import { ApiQueryParams, Response, WithId } from "../../types/api";
import { Api } from "../api-store";
import { Product } from "../product";
import { Offer } from "./offer-types";

export const OfferApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    offers: builder.query<Response<Offer[]>, ApiQueryParams>({
      providesTags: ["offers"],
      query: () => ({
        url: `/offers`,
        method: "GET",
      }),
    }),

    offer: builder.query<Response<Offer>, string | undefined>({
      providesTags: ["offer"],
      query: (id) => ({
        url: `/offers/${id}`,
        method: "GET",
      }),
    }),
    offerProducts: builder.query<Response<Product[]>, WithId<ApiQueryParams>>({
      providesTags: (result, error, { ...params }) => [
        { type: "offer", id: params.id, ...params },
      ],
      query: ({ id, ...params }) => ({
        url: `/offers/${id}/products`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useOfferProductsQuery, useOfferQuery, useOffersQuery } =
  OfferApi;
