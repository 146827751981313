import { useState } from "react";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { ApiQueryParams } from "~/old-app/types/api";
import {
  useOfferProductsQuery,
  useOfferQuery,
  useOffersQuery,
} from "./offer-api";

export const useOfferProducts = (props?: ApiQueryParams) => {
  const { selectedService } = useConfigSlice();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useOfferProductsQuery(
    {
      ...props,
      page: props?.page || 1,
      service_id: selectedService,
    },
    { skip: !selectedService || !props?.id }
  );

  const products = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total !== products.length;

  return {
    products,
    total: data?.response.meta?.total || 0,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
  };
};

export const useOffers = (props?: ApiQueryParams) => {
  const { selectedService } = useConfigSlice();
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useOffersQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    { skip: !selectedService }
  );

  const offers = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total !== offers.length;

  return {
    offers,
    total: data?.response.meta?.total || 0,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
  };
};

export const useOffer = (id?: string) => {
  const { data, isLoading, isFetching } = useOfferQuery(id, { skip: !id });
  return {
    offer: data?.response?.data,
    isLoading,
    isFetching,
  };
};
